.filepond--drop-label {
  display: flex;
  width: 100%;
  min-height: 100px !important;
}
.filepond--root {
  margin-bottom: 0;
  width: 100%;
  height: inherit;
  border-radius: 5px;
  border: 1px dashed #757575;
  min-height: 102px !important;
}

