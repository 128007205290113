body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-textarea {
  padding: 8.5px 14px;
}

.custom-textarea:hover {
  outline: 1px solid black;
}

.custom-textarea:focus-visible {
  outline: 2px solid #536dfe;
}

.custom-textarea::placeholder {
  color: black;
  opacity: 0.6;
}

.table {
  border: 1px solid #b0cbef;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
}

.table th {
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  height: 17px;
}

.table td {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #d0d7e5;
  border-width: 0px 1px 1px 0px;
}

.table td b {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.table td.heading {
  background-color: #e4ecf7;
  text-align: center;
  border: 1px solid #9eb6ce;
  border-width: 0px 1px 1px 0px;
  font-size: 14px;
  font-weight: bold;
}

.table th.heading {
  background-repeat: none;
}

.signatureCanvas {
  /* border: 1px solid black; */
  width: 100%;
  height: 100%;
}


/* Credit card form */
.credit-card {
  padding: 1em;
  display: block;
  width: '100%';
  border-radius: 3;
  border: '1px solid gray'
}

small {
  color: red;
  font-size: 10px;
  display: block;
}